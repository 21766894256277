
<template>
  <div>
    <v-card flat style='border-radius: 18px 18px 0px 0px;'>
      <v-card-title class='primary white--text py-3 px-5'>
        <span v-if="$route.name =='NewCertificateConfig'">Cadastrar nova assinatura</span>
        <span v-if="$route.name =='EditCertificateConfig'">Assinatura</span>
        <v-spacer></v-spacer>
        <v-btn 
          text 
          rounded
          color="white" 
          v-if="$route.name =='EditCertificateConfig'" 
          @click="enable_fields = !enable_fields"
        ><v-icon left>mdi-pencil</v-icon> Habilitar edição</v-btn>
        <v-btn 
          text 
          rounded
          icon 
          color="white"
          class="ml-2" 
          @click="$router.push({name: 'CertificateConfig'})" 
        ><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text class="pt-3">
        <v-form ref="CertificateSettingsForm" :readonly="!enable_fields">
          <v-text-field
            v-model="certificate_setting.name"
            prepend-icon="mdi-account-outline"
            label="Nome"
            :rules="nameRules"
          ></v-text-field>
          <v-text-field
            v-model="certificate_setting.role"
            prepend-icon="mdi-briefcase-edit-outline"
            label="Cargo"
            :rules="required"
          ></v-text-field>
          <v-select
            v-model="certificate_setting.level"
            :items="level"
            item-text="translated"
            item-value="value"
            label="Selecione o nível"
            prepend-icon="mdi-format-align-top"
          ></v-select>
          <v-select
            v-model="certificate_setting.status"
            :items="status"
            item-text="translated"
            item-value="value"
            label="Defina os status"
            prepend-icon="mdi-list-status"
          ></v-select>
          <input-local-file-with-preview
            section_title="Imagem de assinatura"
            accepted_file_options="img"
            :file_name="certificate_setting.signature_img_name"
            :file_url="certificate_setting.signature_img_url"
            :required_field="true"
            :disabled="!enable_fields"
            @fileToUpload="handleFileToUpload"
          />
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center mb-3">
        <v-btn v-if="$route.name =='NewCertificateConfig'" color="success" @click="submitSetting" >
          <v-icon left>mdi-content-save</v-icon> Salvar 
        </v-btn>
        <v-btn 
          color="success" 
          :disabled="!enable_fields" 
          v-if="$route.name =='EditCertificateConfig'" 
          @click="submitSetting" 
        ><v-icon left>mdi-content-save</v-icon> Atualizar </v-btn>
        <v-btn 
          color="error" 
          :disabled="!enable_fields" 
          v-if="$route.name =='EditCertificateConfig'" 
          @click="$router.push({name: 'CertificateConfig'})"
        ><v-icon  left>mdi-cancel</v-icon> Cancelar</v-btn>
      </v-card-actions>
    </v-card>
    <dialog-loading v-if="saving" :loading="saving" loadingText="Salvando"></dialog-loading>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import validations from '@/mixins/validations';
import Api from '@/api/index'

export default {
  name: 'CertificateCreate',
  mixins: [
    validations
  ],
  data() {
    return {
      certificate_setting: {
        name: '',
        role: '',
        level: '',
        status: '',
        signature_img: null,
      },

      level: [
        { value: 'director', translated: 'Diretor Latino-Americano' },
        { value: 'subdirector', translated: 'Diretor Nacional' }
      ],
      status: [
        { value: 'active', translated: 'Ativo' },
        { value: 'inactive', translated: 'Inativo' }
      ],

      saving: false,
      enable_fields: true,
    }
  },
  mounted() {
    this.setCertificateToEdit()
  },
  methods: {
    handleFileToUpload(file){
      this.certificate_setting.signature_img = file
    },
    setCertificateToEdit(){
      if(this.$route.name == 'EditCertificateConfig'){
        this.enable_fields = false
      }
      if(this.$route.params.id){
        this.getSelectedCertificateSettings(this.$route.params.id)
      }
    },
    getSelectedCertificateSettings(id){
      Api.CertificateSetting.show(id).then(r => r.data).then(d => {
        this.certificate_setting = {
          ...d
        }
      }).catch((err)=>{
        this.addNotification({type: 'error', msg: `${err}`})
        if(err.response && err.response.status && err.response.status == 404){
          this.$router.push({name: 'CertificateConfig', params: {any_certificate_settings_updated: false}})
        }
      });
    },
    submitSetting(){
      if (this.$refs.CertificateSettingsForm.validate()) {
        const ACTION = {
          METHOD: this.certificate_setting.id? 'updateWithFormData' : 'createWithFormData',
          TEXT: this.certificate_setting.id? 'atualizar' : 'criar novo',
        }
        
        this.$swal({
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          title: `Deseja ${ACTION.TEXT} assinatura?`,
          icon: 'question',
        }).then(r=>{
          if (r.isConfirmed) {
            this.saveCertificateSetting(ACTION);
          };
        });
      };
    },
    saveCertificateSetting(ACTION){
      this.saving = true;
      
      let settings = this.prepareSettings()
      setTimeout(() => {
        Api.CertificateSetting[ACTION.METHOD](settings).then(() => {
          this.addNotification({type: 'success', msg: 'Realizado com sucesso'})
          this.$router.push({name: 'CertificateConfig', params: {any_certificate_settings_updated : true}})
        }).catch((err) =>{
          this.addNotification({type: 'error', msg: `Erro: ${err}`})
        }).finally(() => {
          this.saving = false;
        })
      }, 100);
    },
    prepareSettings(){
      let settings = {};
      let { signature_img, ...rest } = this.certificate_setting;

      signature_img ? (settings = {signature_img, ...rest}) : (settings = {...rest});

      let settings_form_data = new FormData();

      Object.entries(settings).forEach(([key, value]) => {
        settings_form_data.append(`certificate_setting[${key}]`, value);
      })

      return settings_form_data;
    },
    ...mapActions({
      addNotification: 'Notification/add'
    })
  },
}
</script>

<style scoped>

</style>